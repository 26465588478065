<template>
  <div class="lesson" v-loading="pageLoading">
    <el-dialog
      title="扫码支付"
      :visible.sync="dialogVisible"
      custom-class="payDialog"
      width="279px"
    >
      <div class="toPay">
        <!-- <span>{{buyTime}}s后关闭</span> -->
        <span>暂时只支持「微信」扫码</span>
        <vue-qr :text="payUrl" width="200" height="200" :margin="0"></vue-qr>
        <!-- <p @click="closeBuy">已完成付款</p> -->
      </div>
    </el-dialog>
    <div class="title">我的订单</div>
    <div class="pageMain">
      <div class="catList">
        <p
          v-for="(item, index) in catList"
          :key="index"
          @click="changeList(item.id)"
          :class="{ active: active == item.id }"
        >
          {{ item.name }}
        </p>
      </div>
      <el-table 
        :data="list" 
        header-row-class-name="tableHeader" 
        row-class-name="tableRow"
        style="width: 100%;margin-bottom: 20px">
        <el-table-column label="封面">
          <template slot-scope="scope">
            <img :src="scope.row.goods.cover_mobile || $tuoyuPlaceImg"/>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="商品名称">
          <template slot-scope="scope">
            {{scope.row.goods.name}}
          </template>
        </el-table-column>
        <el-table-column prop="amount" label="价格" align="center"> </el-table-column>
        <el-table-column prop="pay_type" label="支付方式" align="center"> </el-table-column>
        <el-table-column prop="orderno" label="订单编码" align="center" width="230"> </el-table-column>
        <el-table-column prop="address" label="订单状态" align="center"> 
          <!-- 1待支付 2待发货 3待收货 4已完成 5已取消 6用户已删除7用户退款 8已关闭9已评价 -->
          <template slot-scope="scope">
            <span v-if="scope.row.status == 1">待支付</span>
            <span v-if="scope.row.status == 4">已完成</span>
            <span v-if="scope.row.status == 5">已取消</span>
            <span v-if="scope.row.status == 8">已关闭</span>
          </template>
        </el-table-column>
        <el-table-column prop="pay_time" label="支付时间" align="center" width="180"> </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <div class="btn">
              <p @click="toDetail(scope.row)" class="detail">查看详情</p>
              <p v-if="scope.row.status == 1" @click="toPay(scope.row)" class="pay">立即支付</p>
              <p v-if="scope.row.status == 1" @click="toDel(scope.row,5)" class="del">取消订单</p>
              <p v-if="scope.row.status != 1" @click="toDel(scope.row,6)" class="del">删除订单</p>
            </div>
          </template>
        </el-table-column>
      </el-table>
     
      <el-pagination
      :hide-on-single-page="total<=limit"
      @current-change="handleCurrentChange"
      :current-page="page"
      :page-size="limit"
      layout="total, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
    </div>
  </div>
</template>

<script>
import VueQr from "vue-qr";
export default {
  name: "lesson",
  components: {VueQr},
  data() {
    return {
      active: 0,
      catList: [
        {
          name: "全部",
          id: 0,
        },
        {
          name: "待支付",
          id: 1,
        },
        {
          name: "已取消",
          id: 5,
        },
        {
          name: "已完成",
          id: 4,
        },
      ],
      list: [],
      page: 1,
      limit: 10,
      total: 0,
      loading: false,
      pageLoading: false,
      finished: true,
      more: false,
      listLoading: false,

      payUrl: "",
      dialogVisible: false,
    };
  },
  async mounted() {
    window.addEventListener("scroll", this.onLoad);
    this.getData();
  },
  destroyed() {
    window.removeEventListener("scroll", this.onLoad);
  },
  computed: {
    plat() {
      return this.$store.state.plat_id;
    },
  },
  methods: {
    async getData() {
      this.pageLoading = true;
     
        let res = await this.$request.orderList({ status: this.active, plat_id: this.plat, page: this.page, limit: this.limit });
        this.pageLoading = false;
        if (res.code == 0) {
          this.list = res.data;
          this.total = res.total
        } else {
          this.list = [];
          this.total = 0
          this.$toast(res.msg);
        }
        
     
    },
    changeList(id) {
      if (this.active == id) return;
      this.active = id;
      this.page = 1
      this.getData()
    },
    handleCurrentChange(val){
      this.page = val
      this.getData()
    },
    toDetail(item) {
      this.$router.push(`./orderDetail?id=${item.orderno}`);
    },
  
    async toPay(item) {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        
      });
      let res = await this.$request.orderPay(item.orderno);
      if (res.code == 0) {
        this.$toast.clear();
        // 限免商品
        
          this.dialogVisible = true;
          this.payUrl = JSON.parse(res.data.pay).code_url;
          this.isPay = true;
          this.payTimer = setTimeout(() => {
            this.getData();
          }, 1000);
      } else {
        this.$toast(res.msg);
      }
    },
    toDel(item,status){
      this.$confirm('确定删除当前订单?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$request.orderDel(item.orderno,status).then(res=>{
            if(res.code == 0){
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
              this.getData()
            }else{
              this.$toast(res.msg);
            }
          })
          
        }).catch(() => {
                   
        });
      
    },
    getMore() {
      if (this.more) {
        return;
      }
      this.more = true;
      if (this.menu == 2) {
        axios
          .get(`/api/platform/pc/category?flat_id=${this.plat}&page=${this.page}`, {})
          .then((res) => {
            this.more = false;
            console.log(res);
            if (res.code == 0) {
              let list = res.data.category[this.classify].goods;
              this.list = this.list.concat(list);
              this.loading = false;
              if (list.length < 10) {
                this.finished = true;
              } else {
                this.finished = false;
              }
            } else {
              this.$toast(res.msg);
            }
          })
          .catch((err) => {
            console.log(err);
            this.more = false;
          });
      }
    },
  },
  beforeRouteUpdate(to, from, next) {
    console.log("beforeRouteUpdate");
    console.log(to);
    next();
  },
};
</script>

<style lang="scss" scoped>
:deep .payDialog {
  overflow: hidden;
  border-radius: 20px;
  text-align: center;
  .el-dialog__body {
    padding: 0;
  }
}
.toPay {
  padding-bottom: 30px;
  span {
    color: #666;
    display: block;
    padding-bottom: 20px;
  }
  img {
    width: 200px;
  }
  > p {
    cursor: pointer;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    padding: 10px 0;
    width: 200px;
    text-align: center;
    margin: 20px auto 0;
  }
}
.lesson {
  min-height: calc(100vh - 230px);

  overflow: hidden;
  font-family: "SourceHanSans";
  ::v-deep .tableHeader {
    color: #242424;
    font-size: 16px;
    padding-bottom: 10px;
    th{
      background: #f0f2f3;
      border-bottom: 0;
      text-align: center;
      &:first-of-type{
        border-radius: 6px 0 0 6px;
      }
      &:nth-last-of-type(2){
        border-radius: 0 6px 6px 0;
      }
    }
  }
  ::v-deep .tableRow{
    td{
      font-size: 14px;
      color: #333333;
      height: 82px;
      border-top: 10px solid #fff;
      border-bottom: 1px dashed #f0f0f0;
      img{
        width: 110px;
        height: 62px;
        border-radius: 4.5px;
        overflow: hidden;
        object-fit: cover;
      }
    }
  }
  .btn{
    font-size: 14px;
    p{
      cursor: pointer;
      line-height: 30px;
    }
    p:hover{
      text-decoration: underline;
    }
    .pay{
      color: #006bb3;
    }
    .del{
      color: #ee5418;
    }
  }
  > .title {
    font-size: 24px;
    color: #242424;
    font-weight: bold;
    border-bottom: 1px dashed #f0f0f0;
    padding-top: 30px;
    padding-bottom: 20px;
    width: 100%;
  }
  .pageMain {
    .catList {
      width: 100%;
      padding-top: 25px;
      margin-bottom: 20px;
      p {
        display: inline-block;
        color: #242424;
        font-size: 20px;
        cursor: pointer;
        margin-right: 40px;
        &:last-of-type {
          margin-right: 0;
        }
      }
      p.active,
      p:hover {
        color: #006bb3;
        font-weight: bold;
        background: none;
      }
    }
    .item {
      text-align: center;
      &:nth-of-type(1) {
        flex: 1;
      }
      &:nth-of-type(2) {
        flex: 1;
      }
      &:nth-of-type(3) {
        flex: 1;
      }
      &:nth-of-type(4) {
        flex: 1;
      }
      &:nth-of-type(5) {
        flex: 1;
      }
      &:nth-of-type(6) {
        flex: 1;
      }
      &:nth-of-type(7) {
        flex: 1;
      }
      &:nth-of-type(8) {
        flex: 1;
      }
    }
    .menu {
      width: 100%;
      height: 50px;
      border-radius: 6px;
      background: #f0f2f3;
      display: flex;
      p {
        font-size: 16px;
        color: #242424;
      }
    }
    .list {
      display: flex;
    }
  }
}
</style>

<style lang="scss">
body {
  background: #fff;
}
</style>
